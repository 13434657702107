<template>
    <section>
        <b-row class="mb-1">
            <b-col lg="4">
                <b-form-group 
                    label="Nome do responsável"
                    label-for="responsavel"
                    label-class="label_sub_titulo"
                >
                    <VuePerfectScrollbar class="altura_max_input_scrollbar" :settings="settings">
                        <div id="responsavel"> {{ contatoEmpresa.nome }} </div>
                    </VuePerfectScrollbar>
                </b-form-group>
            </b-col>
            <b-col lg="4">
                <b-form-group 
                    label="Cargo"
                    label-for="cargo"
                    label-class="label_sub_titulo"
                >
                    <VuePerfectScrollbar class="altura_max_input_scrollbar" :settings="settings">
                        <div id="cargo"> {{contatoEmpresa.tipoContatoDescricao}}</div>
                    </VuePerfectScrollbar>
                </b-form-group>
            </b-col>
            <b-col lg="4">
                <b-form-group 
                    label="Email"
                    label-for="email"
                    label-class="label_sub_titulo"
                >
                    <VuePerfectScrollbar class="altura_max_input_scrollbar" :settings="settings">
                        <div id="email"> {{contatoEmpresa.email}} </div>
                    </VuePerfectScrollbar>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="4">
                <b-form-group 
                    label="Telefone para contato principal"
                    label-for="telefone"
                    label-class="label_sub_titulo"
                >
                    <VuePerfectScrollbar class="altura_max_input_scrollbar" :settings="settings">
                        <div v-if="contatoEmpresa.telefone != '-'" id="telefone" > {{ contatoEmpresa.telefone | VMask('(##) #####-####') }} </div>
                        <div v-else id="telefone"> {{ contatoEmpresa.telefone }} </div>
                    </VuePerfectScrollbar>
                </b-form-group>
            </b-col>
            <b-col lg="4">
                <b-form-group 
                    label="Telefone para contato secundário"
                    label-for="telefone-secundario"
                    label-class="label_sub_titulo"
                >
                    <VuePerfectScrollbar class="altura_max_input_scrollbar" :settings="settings">
                        <div v-if="contatoEmpresa.telefone_secundario != '-'" id="telefone-secundario">{{ contatoEmpresa.telefone_secundario | VMask('(##) #####-####') }}</div>
                        <div v-else id="telefone-secundario" >{{ contatoEmpresa.telefone_secundario }}</div>
                    </VuePerfectScrollbar>
                </b-form-group>
            </b-col>
        </b-row>
         <b-row v-if="contatoEmpresa.endereco" class="mt-2">
            <b-col lg="12">
                <b-form-group 
                    label="Endereço do contato:"
                    label-for="telefone"
                    label-class="label_sub_titulo"
                >
                    <VuePerfectScrollbar class="altura_max_input_scrollbar" :settings="settings">
                        <div id="telefone">
                            {{ 
                                (contatoEmpresa.endereco.logradouro ? contatoEmpresa.endereco.logradouro : '--') + ' n ' + 
                                (contatoEmpresa.endereco.numero ? contatoEmpresa.endereco.numero : '--') + ' ' + 
                                (contatoEmpresa.endereco.bairro ? contatoEmpresa.endereco.bairro : '--') + ', ' + 
                                (contatoEmpresa.endereco.cidade ? contatoEmpresa.endereco.cidade : '--') + ' - ' +
                                (contatoEmpresa.endereco.estado ? contatoEmpresa.endereco.estado : '--') + ' CEP: '
                            }} 

                            {{ (contatoEmpresa.endereco.cep ? contatoEmpresa.endereco.cep : '') | VMask('#####-###') }}. 
                        </div>
                    </VuePerfectScrollbar>
                </b-form-group>
            </b-col>
            
        </b-row>
    </section>
</template>

<script>
import { BFormGroup, BCol, BRow } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
    components: {
        BFormGroup,
        BCol, 
        BRow,
        VuePerfectScrollbar
    },
    
    props: {
        contatoEmpresa: {
            type: Array|Object,
            required: true,
        }
    },

    data() {
        return {
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            }
        }
    }
}
</script>

<style>
    .altura_max_input_scrollbar{
        max-height:32px;
        word-break: break-all;
        padding-right: 15px;
    }
    .label_sub_titulo{
        font-size: 14px;
        font-weight: 600;
    }
</style>